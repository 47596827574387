var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "elevation-0 pt-1 px-2 card",
      attrs: {
        "min-height": "100px",
        "max-height": "100px",
        "min-width": "238px",
        "max-width": "238px"
      }
    },
    [
      !_vm.hasError
        ? [
            _c(
              "v-card-title",
              { staticClass: "primary--text card-title" },
              [
                _vm.isLoading
                  ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                  : _vm._e(),
                _c("span", [_vm._v(" " + _vm._s(_vm.evaluatedTitle) + " ")])
              ],
              1
            ),
            _c("v-card-subtitle", { staticClass: "primary_font--text" }, [
              _vm._v(_vm._s(_vm.subtitle))
            ])
          ]
        : [
            _c("v-card-title", { staticClass: "card-title error--text" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("components.cards.HighlightedCard.error_title")
                  ) +
                  " "
              )
            ]),
            _c("v-card-subtitle", { staticClass: "primary_font--text" }, [
              _vm._v(_vm._s(_vm.computedErrorSubtitle))
            ])
          ],
      _c(
        "div",
        { staticClass: "highlighted-card-absolute" },
        [_vm._t("default")],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }