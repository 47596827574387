<template>
    <v-container fluid class="pt-0 px-0">
        <div class="ml-md-2 pl-7 pl-md-5 pl-md-12 section-title">
            {{ $t('views.dashboard.title') }}
        </div>

        <!-- Seção de cartões em destaque -->
        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mt-0 mx-4">
            <v-col :cols="cardCols" v-for="(item, index) in highlightedCards" :key="index">
                <HighlightedCard
                    :ref="item.ref"
                    :title="item.title"
                    :subtitle="item.subtitle"
                    class="mx-2"
                    :update-title="report_period"
                >
                    <!-- [TODO] Configurador -->
                    <!-- <v-btn icon small @click="() => null" class="mr-3" disabled>
                        <v-icon small color="grey lighten-1">fas fa-cog</v-icon>
                    </v-btn> -->
                </HighlightedCard>
            </v-col>
        </v-row>
        <div v-else class="d-flex mt-4 pb-2 card-container">
            <HighlightedCard
                v-for="(item, index) in highlightedCards"
                :key="index"
                :ref="item.ref"
                :title="item.title"
                :subtitle="item.subtitle"
                class="mx-2"
                :update-title="report_period"
            >
                <!-- [TODO] Configurador -->
                <!-- <v-btn icon small @click="() => null" class="mr-3" disabled>
                    <v-icon small color="grey lighten-1">fas fa-cog</v-icon>
                </v-btn> -->
            </HighlightedCard>
        </div>

        <v-row class="mx-4 mx-md-9 mb-2">
            <v-col class="px-0 d-flex align-center" cols="12" sm="6">
                <router-link :to="{ name: 'reports' }" tag="small" class="clickable">
                    {{ $t('views.dashboard.cards.sub_note') }}
                </router-link>
            </v-col>
            <v-col class="text-right d-flex flex-column align-end px-0" cols="12" sm="6">
                <div class="d-flex align-center">
                    <label class="mr-3">{{ $t('views.dashboard.filter.label') }}</label>
                    <SelectInput
                        max-width="130px"
                        outlined dense hide-details
                        v-model="report_period"
                        :items="availablePeriods"
                    />
                </div>
            </v-col>
        </v-row>

        <v-row class="mx-4 mx-md-9 mb-2">
            <!-- Seção de últimas notificações -->
            <v-col cols="12" xm="12" sm="12" md="6" class="px-0 pr-md-2 pb-0">
                <div class="mb-2">
                    <span class="ml-4 primary--text subsection-title">{{ $t('views.dashboard.subsections.last_notifications.title') }}</span>
                </div>

                <ItemCard
                    v-for="(item, index) in notificationsCards"
                    :key="index"
                    :ref="`${item.key}_${index}`"
                    :title="item.title"
                    :subtitle="item.subtitle"
                    :aside="item.aside"
                    class="my-2"
                ></ItemCard>

                <div class="d-flex justify-center">
                    <v-btn :disabled="true" text small class="primary--text see-all-btn">{{ $t('views.dashboard.subsections.last_notifications.see_all') }}</v-btn>
                </div>
            </v-col>

            <!-- Seção de últimos clientes registrados -->
            <v-col cols="12" xm="12" sm="12" md="6" class="px-0 pl-md-2 pt-6 pt-md-3 pb-0">
                <div class="mb-2">
                    <span class="ml-4 primary--text subsection-title">{{ $t('views.dashboard.subsections.last_registered_customers.title') }}</span>
                </div>

                <ItemCard
                    v-for="(item, index) in registeredCustomersCards"
                    :key="index"
                    :ref="`${item.key}_${index}`"
                    :title="item.title"
                    :subtitle="item.subtitle"
                    :aside="item.aside"
                    class="my-2"
                ></ItemCard>

                <div class="d-flex justify-center show-all-text">
                    <v-btn :disabled="true" text small class="primary--text see-all-btn">{{ $t('views.dashboard.subsections.last_registered_customers.see_all') }}</v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import HighlightedCard from '@/components/cards/HighlightedCard'
import ItemCard from '@/components/cards/ItemCard'
import SelectInput from '@/shared/components/inputs/SelectInput'
import CustomerRepository from '@/repositories/CustomerRepository'
import VoucherRepository from '@/repositories/VoucherRepository'
import GeneratorRepository from '@/repositories/GeneratorRepository'
import ConsumptionRepository from '@/repositories/ConsumptionRepository'

export default {
    name: 'Dashboard',
    components: {
        HighlightedCard,
        ItemCard,
        SelectInput,
    },
    data: vm => ({
        availablePeriods: [
            { value: '1:DAY',   text: vm.$tc('views.dashboard.filter.generic_day', 1) },
            { value: '3:DAY',   text: vm.$tc('views.dashboard.filter.generic_day', 3) },
            { value: '7:DAY',   text: vm.$tc('views.dashboard.filter.generic_day', 7) },
            { value: '15:DAY',  text: vm.$tc('views.dashboard.filter.generic_day', 15) },
            { value: '30:DAY',  text: vm.$tc('views.dashboard.filter.generic_day', 30) },
            { value: '60:DAY',  text: vm.$tc('views.dashboard.filter.generic_day', 60) },
            { value: '90:DAY',  text: vm.$tc('views.dashboard.filter.generic_day', 90) },
        ],
        report_period: '7:DAY',
    }),
    computed: {
        highlightedCards() {
            let self = this
            // catch tratado no componente
            return [
                {
                    ref     : 'registered_customers',
                    subtitle: this.$t('views.dashboard.cards.registered_customers.subtitle'),
                    async title() {
                        let result = await CustomerRepository.fetchReport({}, { ...self.computedPeriod })
                        return this.$lodash.get(result, 'data.model_count')
                    },
                }, {
                    ref     : 'generator_count',
                    subtitle: this.$t('views.dashboard.cards.generator_count.subtitle'),
                    async title() {
                        let result = await GeneratorRepository.fetchReport({}, { ...self.computedPeriod })
                        return this.$lodash.get(result, 'data.model_count')
                    },
                }, {
                    ref     : 'consumption_count',
                    subtitle: this.$t('views.dashboard.cards.consumption_count.subtitle'),
                    async title() {
                        let result = await ConsumptionRepository.fetchReport({}, { ...self.computedPeriod })
                        return this.$lodash.get(result, 'data.model_count')
                    },
                }, {
                    ref     : 'generated_points',
                    subtitle: this.$t('views.dashboard.cards.generated_points.subtitle'),
                    async title() {
                        // [TODO]
                        return this.$t('globals.coming_soon')
                    },
                }, {
                    ref     : 'consumed_points',
                    subtitle: this.$t('views.dashboard.cards.consumed_points.subtitle'),
                    async title() {
                        // [TODO]
                        return this.$t('globals.coming_soon')
                    },
                }, {
                    ref     : 'generated_vouchers',
                    subtitle: this.$t('views.dashboard.cards.generated_vouchers.subtitle'),
                    async title() {
                        let result = await VoucherRepository.fetchReport({}, { ...self.computedPeriod, filter_by: 'created_at' })
                        return this.$lodash.get(result, 'data.model_count')
                    },
                }, {
                    ref     : 'buyed_vouchers',
                    subtitle: this.$t('views.dashboard.cards.buyed_vouchers.subtitle'),
                    async title() {
                        // [TODO]
                        return this.$t('globals.coming_soon')
                    },
                }, {
                    ref     : 'withdrawn_vouchers',
                    subtitle: this.$t('views.dashboard.cards.withdrawn_vouchers.subtitle'),
                    async title() {
                        let result = await VoucherRepository.fetchReport({}, { ...self.computedPeriod, filter_by: 'withdrawn_at' })
                        return this.$lodash.get(result, 'data.model_count')
                    },
                },
            ]
        },
        notificationsCards() {
            return [
                // [TODO] Implementar quando funcionalidade estiver disponível
                { ref: 'notification', title: this.$t('views.dashboard.coming_soon.notifications.title'), subtitle: this.$t('views.dashboard.coming_soon.notifications.subtitle') },
            ]
        },
        registeredCustomersCards() {
            return [
                // [TODO] Implementar quando funcionalidade estiver disponível
                { ref: 'customer_register', title: this.$t('views.dashboard.coming_soon.registered_customers.title'), subtitle: this.$t('views.dashboard.coming_soon.registered_customers.subtitle') },
            ]
        },
        cardCols() {
            return this.$vuetify.breakpoint.xlAndUp ? 2 :
                   this.$vuetify.breakpoint.lgAndUp ? 3 : 4
        },
        computedPeriod() {
            let split  = this.report_period.split(':')
            let amount = split[0]
            let period = split[1]
            let from   = amount && period ? this.$moment().subtract(amount, period).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ') : null
            return { from }
        },
    },
}
</script>

<style scoped lang="scss">
.card-container {
    overflow-x: auto;
}

::-webkit-scrollbar {
    display: none;
}

.subsection-title {
    font-weight: bold;
}

.see-all-btn {
    font-size: 14px;
}
</style>