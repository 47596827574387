<template>
    <!-- É preciso definir a altura e a largura dessa forma para que o scroll horizontal funcione na dashboard -->
    <v-card
        min-height="100px"
        max-height="100px"
        min-width="238px"
        max-width="238px"
        class="elevation-0 pt-1 px-2 card"
    >
        <template v-if="!hasError">
            <v-card-title class="primary--text card-title">
                <v-progress-linear v-if="isLoading" indeterminate/>
                <span>
                    {{ evaluatedTitle }}
                </span>
            </v-card-title>
            <v-card-subtitle class="primary_font--text">{{ subtitle }}</v-card-subtitle>
        </template>
        <template v-else>
            <v-card-title class="card-title error--text">
                {{ $t('components.cards.HighlightedCard.error_title') }}
            </v-card-title>
            <v-card-subtitle class="primary_font--text">{{ computedErrorSubtitle }}</v-card-subtitle>
        </template>

        <div class="highlighted-card-absolute">
            <slot></slot>
        </div>
    </v-card>
</template>

<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    name: 'HighlightedCard',
    data: vm => ({
        hasError       : false,
        isLoading      : true,
        evaluatedTitle : '...',
        errorStatusCode: null,
    }),
    mixins: [ HasErrorHandlerMixin ],
    methods: {
        async evaluateTitle() {
            this.errorStatusCode = null
            if (typeof this.title == 'string') {
                this.evaluatedTitle = this.title
                this.isLoading = false
                return
            }

            this.isLoading = true
            let result = await this.title()
                .catch(this.preErrorHandler)
            if (result === undefined) {
                this.preErrorHandler()
            }

            this.evaluatedTitle = result
            this.isLoading = false
        },
        preErrorHandler(e) {
            this.isLoading       = false
            this.hasError        = true
            this.errorStatusCode = this.$lodash.get(e, 'response.status')

            this.errorHandler(e)
        }
    },
    props: {
        title: {
            type: [ String, Function ],
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        updateTitle: [Object, String, Number, Function],
    },
    computed: {
        computedErrorSubtitle() {
            let errorMessageIsDefined = !this.$lodash.isEmpty(this.$t(`errors.simple.http`)[this.errorStatusCode])
            return errorMessageIsDefined ? this.$t(`errors.simple.http.${this.errorStatusCode}`) : this.$t('components.cards.HighlightedCard.error_subtitle')
        },
    },
    watch: {
        updateTitle: {
            deep: true,
            immediate: true,
            handler() {
                this.evaluateTitle()
            },
        },
    },
}
</script>

<style scoped lang="scss">
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}

.card {
    position: relative;
    border: solid 1px var(--v-primary-base);
    z-index: 1
}

.card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--v-primary-base);
    opacity: .1;
    border-radius: 8px;
    z-index: -1;
}

.card-title {
    font-size: 24px;
    font-weight: bold;
}

.highlighted-card-absolute {
    position: absolute;
    top: 5px;
    right: -6px;
}
</style>