var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0 px-0", attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "ml-md-2 pl-7 pl-md-5 pl-md-12 section-title" },
        [_vm._v(" " + _vm._s(_vm.$t("views.dashboard.title")) + " ")]
      ),
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-row",
            { staticClass: "mt-0 mx-4" },
            _vm._l(_vm.highlightedCards, function(item, index) {
              return _c(
                "v-col",
                { key: index, attrs: { cols: _vm.cardCols } },
                [
                  _c("HighlightedCard", {
                    ref: item.ref,
                    refInFor: true,
                    staticClass: "mx-2",
                    attrs: {
                      title: item.title,
                      subtitle: item.subtitle,
                      "update-title": _vm.report_period
                    }
                  })
                ],
                1
              )
            }),
            1
          )
        : _c(
            "div",
            { staticClass: "d-flex mt-4 pb-2 card-container" },
            _vm._l(_vm.highlightedCards, function(item, index) {
              return _c("HighlightedCard", {
                key: index,
                ref: item.ref,
                refInFor: true,
                staticClass: "mx-2",
                attrs: {
                  title: item.title,
                  subtitle: item.subtitle,
                  "update-title": _vm.report_period
                }
              })
            }),
            1
          ),
      _c(
        "v-row",
        { staticClass: "mx-4 mx-md-9 mb-2" },
        [
          _c(
            "v-col",
            {
              staticClass: "px-0 d-flex align-center",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "clickable",
                  attrs: { to: { name: "reports" }, tag: "small" }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("views.dashboard.cards.sub_note")) + " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "text-right d-flex flex-column align-end px-0",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("label", { staticClass: "mr-3" }, [
                    _vm._v(_vm._s(_vm.$t("views.dashboard.filter.label")))
                  ]),
                  _c("SelectInput", {
                    attrs: {
                      "max-width": "130px",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      items: _vm.availablePeriods
                    },
                    model: {
                      value: _vm.report_period,
                      callback: function($$v) {
                        _vm.report_period = $$v
                      },
                      expression: "report_period"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mx-4 mx-md-9 mb-2" },
        [
          _c(
            "v-col",
            {
              staticClass: "px-0 pr-md-2 pb-0",
              attrs: { cols: "12", xm: "12", sm: "12", md: "6" }
            },
            [
              _c("div", { staticClass: "mb-2" }, [
                _c(
                  "span",
                  { staticClass: "ml-4 primary--text subsection-title" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "views.dashboard.subsections.last_notifications.title"
                        )
                      )
                    )
                  ]
                )
              ]),
              _vm._l(_vm.notificationsCards, function(item, index) {
                return _c("ItemCard", {
                  key: index,
                  ref: item.key + "_" + index,
                  refInFor: true,
                  staticClass: "my-2",
                  attrs: {
                    title: item.title,
                    subtitle: item.subtitle,
                    aside: item.aside
                  }
                })
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary--text see-all-btn",
                      attrs: { disabled: true, text: "", small: "" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.dashboard.subsections.last_notifications.see_all"
                          )
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            2
          ),
          _c(
            "v-col",
            {
              staticClass: "px-0 pl-md-2 pt-6 pt-md-3 pb-0",
              attrs: { cols: "12", xm: "12", sm: "12", md: "6" }
            },
            [
              _c("div", { staticClass: "mb-2" }, [
                _c(
                  "span",
                  { staticClass: "ml-4 primary--text subsection-title" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "views.dashboard.subsections.last_registered_customers.title"
                        )
                      )
                    )
                  ]
                )
              ]),
              _vm._l(_vm.registeredCustomersCards, function(item, index) {
                return _c("ItemCard", {
                  key: index,
                  ref: item.key + "_" + index,
                  refInFor: true,
                  staticClass: "my-2",
                  attrs: {
                    title: item.title,
                    subtitle: item.subtitle,
                    aside: item.aside
                  }
                })
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-center show-all-text" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary--text see-all-btn",
                      attrs: { disabled: true, text: "", small: "" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.dashboard.subsections.last_registered_customers.see_all"
                          )
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }