import { render, staticRenderFns } from "./HighlightedCard.vue?vue&type=template&id=4e837052&scoped=true&"
import script from "./HighlightedCard.vue?vue&type=script&lang=js&"
export * from "./HighlightedCard.vue?vue&type=script&lang=js&"
import style0 from "./HighlightedCard.vue?vue&type=style&index=0&id=4e837052&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e837052",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VProgressLinear})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-admin/cplug-fid-front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4e837052')) {
      api.createRecord('4e837052', component.options)
    } else {
      api.reload('4e837052', component.options)
    }
    module.hot.accept("./HighlightedCard.vue?vue&type=template&id=4e837052&scoped=true&", function () {
      api.rerender('4e837052', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/cards/HighlightedCard.vue"
export default component.exports